import type { ROLES_CODES } from '~/constants/roles'

export enum PERMISSIONS {
  EDIT_PERSONAL_USER_PROFILE_AS_NEEDED = 'edit-personal-user-profile-as-needed',
  VIEW_DETAILS_OF_OWN_REFERRALS_CREATED = 'view-details-of-own-referrals-created',
  CREATE_A_NEW_REFERRAL = 'create-a-new-referral',
  CAN_DELETE_ANY_REFERRAl = 'delete-any-referral',
  VIEW_STATISTICS_FOR_REPORTED_PARTNERS_ONLY = 'view-statistics-for-reported-partners-only',
  CONFIGURE_PERMISSIONS_ON_THE_ROLE = 'configure-permissions-on-the-role',
  ASSIGN_MANAGER_OR_ADMIN_ROLE_TO_USERS = 'assign-manager-or-admin-role-to-users',
  DELETE_DE_ACTIVATE_USER_PROFILE = 'delete-de-activate-user-profile',
  ACCESS_TO_MANAGEMENT_ADMIN_SECTION = 'access-to-management-admin-section',
  TURN_ON_OR_OFF_USERS_2FA = 'turn-on-or-off-users-2fa',
  EDIT_USER_PROFILE_AS_NEEDED = 'edit-user-profile-as-needed',
  ASSIGN_MANAGER_TO_NEWLY_REGISTERED_PARTNER = 'assign-manager-to-newly-registered-partner',
  VIEW_DETAILS_OF_ALL_REFERRALS_CREATED = 'view-details-of-all-referrals-created',
  VIEW_DETAILS_OF_REFERRALS_FROM_ASSOCIATED_PARTNERS = 'view-details-of-referrals-from-associated-partners',
  ASSIGN_SALES_AGENT_TO_REFERRAL = 'assign-sales-agent-to-referral',
  EDIT_COMMISSIONS_ASSOCIATED_WITH_REFERRAL = 'edit-commissions-associated-with-referral',
  VIEW_STATISTICS_FOR_ASSOCIATED_PARTNERS_ONLY = 'view-statistics-for-associated-partners-only',
  VIEW_REFERRAL_COMMISSION = 'view-referral-commission',
  CAN_MANAGE_PARTNERS = 'can-manage-partners',
  EDIT_OWN_PARTNER_DETAILS_OR_WITHIN_REPORTING_LINE = 'edit-own-partner-details-or-within-reporting-line',
  EDIT_ANY_PARTNER_DETAILS = 'edit-any-partner-details',
  EDIT_OWN_REFERRAL_DETAILS_OR_WITHIN_REPORTING_LINE = 'edit-own-referral-details-or-within-reporting-line',
  EDIT_ANY_REFERRAL_DETAILS = 'edit-any-referral-details',
  APPLY_PERSONAL_COMMISSIONS_FOR_MANAGERS = 'apply-personal-commissions-for-managers',
  UPDATE_DEFAULT_COMMISSIONS_FOR_PARTNERS_AND_MANAGERS = 'update-default-commissions-for-partners-and-managers',
  APPLY_PERSONAL_COMMISSIONS_FOR_PARTNERS = 'apply-personal-commissions-for-partners',
  ADD_PARTNER_TO_DEAL = 'add-partner-to-deal',
  SEND_COMMISSION_GROUP_TO_REVIEW = 'send-commission-group-to-review',
  CAN_APPROVE_COMMISSION_GROUP = 'can-approve-commission-group',
  CAN_VIEW_DEAL_COMMISSIONS = 'can-view-deal-commissions',
  VIEW_PROPERTY_CATALOGUE = 'view-property-catalogue',
  CAN_APPROVE_PAYOUTS = 'can-approve-payouts',
  CAN_CONFIRM_PAYOUTS = 'can-confirm-payouts',
  CAN_VIEW_PAYOUTS = 'can-view-payouts',
}

export type Permission = {
  id: number
  description: string
  name: PERMISSIONS
  group: {
    id: number
    name: string
  }
}

export type Role = {
  id: number
  name: string
  code: ROLES_CODES
  description: string
  permissions: Permission[]
}

export type Pivot = {
  model_type: string
  model_id: number
  role_id: number
}
